<template>
  <div class="d-flex flex-column align-items-center">
    <p style="margin-bottom: 0px; font-size: 14px">{{ label }}</p>
    <div class="tri-state-toggle">
      <button
        v-for="(option, index) in options"
        :key="'option-' + index"
        :class="`tri-state-toggle-button w-100 ${buttonClass} ${
          option.value == value ? 'active' : ''
        }`"
        @click="onOptionSelect(option)"
      >
        {{ option.label }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      default: "Select any one option:",
      type: String,
    },
    options: {
      default: [{ label: "", value: "" }],
      type: Array,
    },
    value: {
      default: "",
    },
    buttonClass: {
      default: "",
    },
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    onOptionSelect(option) {
      this.$emit("input", option.value);
    },
  },
  beforeMount() {},
};
</script>
<style scoped>
.tri-state-toggle {
  background: rgba(165, 170, 174, 0.25);
  box-shadow: inset 0 2px 8px 0 rgba(165, 170, 174, 0.25);
  border-radius: 24px;
  display: inline-block;
  overflow: hidden;
  display: inline-flex;
  flex-direction: row;
  transition: all 500ms ease;
}

.tri-state-toggle-button {
  border-radius: 22px;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  background-color: transparent;
  border: 0px solid transparent;
  margin: 2px;
  color: #727c8f;
  cursor: pointer;

  /*    -webkit-transition: all 0.5s ease-in-out;
  -moz-transition:    all 0.5s ease-in-out;
  -ms-transition:     all 0.5s ease-in-out;
  -o-transition:      all 0.5s ease-in-out; */
  transition: all 0.5s ease;
}

.tri-state-toggle-button.active {
  background-image: linear-gradient(
    -180deg,
    #fff 0%,
    #fafafa 81%,
    #f2f2f2 100%
  );
  border: 1px solid rgba(207, 207, 207, 0.6);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  color: black;
  font-weight: 500;
  transition: all 0.5s ease-in;
}

.tri-state-toggle-button:focus {
  outline: none;
}
</style>
